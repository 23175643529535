import { Directive, Input, Inject, ElementRef } from "@angular/core";
import { PlatformHelpersService } from "../services/platform.helpers.module/platform.helpers.service";
import { TrackingService } from "../services/tracking.module/tracking.service";

@Directive({
    selector: '[action-tracking]',
    standalone: true,
})
export class ActionTrackingDirective {
    private _cal: string;
    private category: string;
    private action: string;
    private label: string;

    @Input('action-tracking-values') set cal(value: string) {
        this._cal = value;
        if (value) {
            var split = value.split(',');
            if (!value || split.length != 3) {
                throw new Error("action-tracking-values must be defined and consist of 3 comma separated values");
            }
            this.category = split[0].trim();
            this.action = split[1].trim();
            this.label = split[2].trim();
        }
    }
    get cal(): string {
        return this._cal;
    }

    constructor(
        private el: ElementRef,
        private platformService: PlatformHelpersService,
        private trackingService: TrackingService) {
        this.AddClickListener();
    }

    private AddClickListener() {
        if (this.platformService.IsBrowserPlatform) {
            this.el.nativeElement.addEventListener("click", this.OnClicked.bind(this));
        }
    }

    private OnClicked() {
        if (this.label) {
            this.trackingService.TrackAction(this.category, this.action, this.label);
        }
    }
}